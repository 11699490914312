import React, { useContext, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import {
  formatDate,
  isDateExpired,
  NGlicSpinner,
  PageEnum,
  PrimaryButton,
  useNglScreenSize,
} from '@nglic/component-lib/build';
import styles from './Profile.module.scss';
import profileIllustration from '../../../assets/img/profileIllustration/profile-image-cover.png';
import profileIllustration2x from '../../../assets/img/profileIllustration/profile-image-cover@2x.png';
import profileIllustration3x from '../../../assets/img/profileIllustration/profile-image-cover@3x.png';
import TableRow from './TableRow';
import { BasicInfoState } from 'components/shared/basicUserInfo.reducer';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'rootReducer';
import { AppPageLayoutWrapper } from '../AppPageLayoutWrapper';
import { editProfile, fetchUserProfile } from './profile.action';
import { Address, Agent, Phone } from '../../../service/service.types';
import { EditProfile } from './EditProfile/EditProfile';
import { FeatureWrapper } from '../../modules/FeatureWrapper/FeatureWrapper';
import AuthContext from '../../context/AuthContext';
import EditIcon from '@material-ui/icons/Edit';
import { fetchStates } from '../../shared/state.action';
import { StateMap } from '../../shared/state.reducer';
import { getItemInLocalStorageIfExists } from '../../../util/getItemInLocalStorageIfExists';
import { getUserInformation } from '../../shared/selectors/getUserInformation';
import { StateLicenseSection } from './StateLicenseSection';
import { filterProducerIds } from '@nglic/utilities-ts/build';
export enum ProfilePages {
  EDIT_PROFILE = 'edit-profile',
}
const routePrefix = '/profile/edit-profile';
export const profileRoutes = {
  EDIT_PROFILE: `${routePrefix}/${ProfilePages.EDIT_PROFILE}`,
};

export enum AddProfilePages {
  EDIT_PROFILE = 'edit-profile',
}

enum ProfileStates {
  LOADING,
  EDIT,
  DEFAULT,
}

const CONTACT_ORDER = [
  {
    label: 'Home Phone',
    key: 'HOME_LANDLINE',
  },
  {
    label: 'Work Phone',
    key: 'WORK_LANDLINE',
  },
  {
    label: 'Cell Phone',
    key: 'CELL',
  },
  {
    label: 'Home Fax',
    key: 'HOME_FAX',
  },
  {
    label: 'Work Fax',
    key: 'WORK_FAX',
  },
  {
    label: 'Home Address',
    key: 'Home Address',
  },
  {
    label: 'Work Address',
    key: 'Work Address',
  },
];

export const Profile: React.FC<{
  routeTo: (route: string) => void;
}> = () => {
  const dispatch = useDispatch();
  const [edit, setEdit] = useState<boolean>(false);
  const [profileState, setProfileStates] = useState<ProfileStates>(
    ProfileStates.LOADING,
  );
  const [agentProfile, setAgentProfile] = React.useState<Agent | undefined>();
  const [roles, setRoles] = React.useState<string[]>([]);
  const basicInfo = useSelector((state: AppState) => getUserInformation(state));

  const agentProfileRedux = useSelector(
    (state: AppState) => state.presentation.addProfile,
  );

  const { isMobile } = useNglScreenSize();

  const stateMap: StateMap | undefined = useSelector(
    (state: AppState) => state.data.state.stateMap,
  );

  useEffect(() => {
    if (basicInfo) {
      dispatch(fetchUserProfile());
    }
  }, [basicInfo]);

  useEffect(() => {
    if (basicInfo) {
      setRoles(basicInfo.roles);
    }
  }, [basicInfo]);

  useEffect(() => {
    if (!stateMap) {
      dispatch(fetchStates());
    }
  }, [stateMap]);

  useEffect(() => {
    if (agentProfileRedux === 'NOT_INITIALIZED') {
      return;
    }

    setAgentProfile(agentProfileRedux);
    if (!edit) {
      setProfileStates(ProfileStates.DEFAULT);
    } else {
      setProfileStates(ProfileStates.EDIT);
    }
  }, [agentProfileRedux, edit]);

  const isAmlCertDateExpired = useMemo(() => {
    return isDateExpired(agentProfile?.amlCertificate?.expirationDate || '');
  }, [agentProfile?.amlCertificate?.expirationDate]);

  const contactData = agentProfile?.contacts.reduce((acc, current) => {
    const newAcc = acc;
    newAcc[current.name] = current.name;
    return newAcc;
  }, {});

  const updateProfile = (params: {
    addresses: Address[];
    phones: Phone[];
    removedPhones: Phone[];
  }) => {
    dispatch(editProfile(params));
    setEdit(false);
    dispatch({
      type: 'FETCH_USER_PROFILE_CLEAR',
    });
  };

  const renderEditButton = () => {
    if (isMobile) {
      return (
        <div onClick={() => setEdit(true)} className={styles['header-button']}>
          {' '}
          <EditIcon
            style={{
              color: '#005F86',
              height: '20px',
              paddingTop: '3px',
              paddingRight: '3px',
            }}
          />{' '}
          <div style={{ paddingTop: '5px', color: '#005F86' }}>EDIT</div>
        </div>
      );
    } else {
      return <PrimaryButton text="EDIT" onClick={() => setEdit(true)} />;
    }
  };

  const renderComponentForState = (state: ProfileStates) => {
    switch (state) {
      case ProfileStates.LOADING:
        return (
          <div className={styles['loading-container']}>
            <NGlicSpinner />
          </div>
        );
      case ProfileStates.EDIT:
        return (
          <EditProfile
            onCancel={() => setEdit(false)}
            currentAgent={agentProfile}
            onSave={updateProfile}
            onSuccess={() => {
              setEdit(false);
              dispatch({
                type: 'EDIT_PROFILE_CLEAR',
              });
              dispatch(fetchUserProfile());
            }}
          />
        );
      default:
        return (
          <>
            <FeatureWrapper featureName={'edit_profile'} params={{ roles }}>
              <div className={styles['header']}>
                <div className={styles['header-text']}>My Information</div>
                {renderEditButton()}
              </div>
            </FeatureWrapper>
            <div className={styles['table']}>
              {contactData &&
                CONTACT_ORDER.map((contact) => {
                  return (
                    <TableRow
                      label={contact.label}
                      text={contactData[contact.key]}
                    />
                  );
                })}
            </div>
          </>
        );
    }
  };

  const renderTrainingAndLicenses = (state: ProfileStates) => {
    if (state === ProfileStates.LOADING) {
      return null;
    }

    return (
      <>
        <div className={styles['section-title']}>
          <div className={styles['section-title-text']}>Training & License</div>
        </div>
        <div className={styles['section']}>
          <div className={styles['content']}>
            <div className={styles['title']}>
              Anti-Money Laundering Training
            </div>
            <div
              className={classNames(styles['aml-date'], {
                [styles['expired']]: isAmlCertDateExpired,
              })}
            >
              {isAmlCertDateExpired ? 'Expired' : 'Expires'}{' '}
              {agentProfile?.amlCertificate?.expirationDate}
            </div>
          </div>
          <div className={styles['table']}>
            {stateMap && agentProfile?.stateLicenses && (
              <FeatureWrapper featureName={'user_profile_state_license'}>
                <div className={styles['table']}>
                  <StateLicenseSection
                    stateMap={stateMap}
                    licenses={agentProfile?.stateLicenses}
                  />
                </div>
              </FeatureWrapper>
            )}
          </div>
        </div>
      </>
    );
  };

  const producerIds = useMemo(() => {
    return filterProducerIds(agentProfile?.producerIds);
  }, [agentProfile]);

  return (
    <AppPageLayoutWrapper title={PageEnum.PROFILE} page={PageEnum.PROFILE}>
      <div className={styles['container']} data-testid="profile-page">
        <div className={styles['section']}>
          <div className={styles['image-container']}>
            <img
              className={styles['responsive-img']}
              alt={'-'}
              srcSet={`${profileIllustration}, ${profileIllustration2x} 2x, ${profileIllustration3x} 3x`}
            />
          </div>
          <p className={styles['title']}>{agentProfile?.name}</p>
          <p className={styles['product-id']}>
            Producer ID(s)#: {producerIds.join(', ')}
          </p>
        </div>
        <div className={styles['section']}>
          {renderComponentForState(profileState)}
        </div>
        {renderTrainingAndLicenses(profileState)}
      </div>
    </AppPageLayoutWrapper>
  );
};
