import produce from 'immer';
import { Action } from 'redux';
import { FetchStateSuccessAction, StateActionType } from './state.action';
import { State } from '../../service/service.types';

export type StateMap = { [key: string]: string };

export interface StateState {
  states: State[] | undefined;
  stateMap: StateMap | undefined;
}

export const stateReducer = (
  state: StateState = { states: undefined, stateMap: undefined },
  action: Action<StateActionType>,
): StateState => {
  return produce(state, (draftState) => {
    switch (action.type) {
      case 'STATE_FETCH_SUCCESS':
        const states = (action as FetchStateSuccessAction).result;
        draftState.states = states;
        draftState.stateMap = states.reduce((acc, cur) => {
          const mutAcc = acc;
          mutAcc[cur.code] = cur.desc;
          return mutAcc;
        }, {});
        return draftState;
      case 'STATE_FETCH_FAILURE':
        draftState.states = [];
        return draftState;
      default:
        return state;
    }
  });
};
